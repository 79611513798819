import { Card, CardContent, Typography, Box, Avatar, Button, Tabs, Tab, Tooltip } from "@mui/material";
import { useState } from "react";

import UserVipCodeOverView from "./UserVipCodeOverView";
import UserVipCodePlans from "./UserVipCodePlans";
import UserVipMealPlans from "../UserVipMealPlans/UserVipMealPlans";
import VipUserHeader from "../VipUserHeader";

const UserVipCodeTabs = ({
    userVipCode,
    setLoadingText
}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box>
            {/* Header Section */}
            <Card sx={{ maxWidth: "100%", bgcolor: "#f8fafc", p: 2 }}>
                <CardContent>
                    <VipUserHeader userVipCode={userVipCode} />

                    {/* Tabs Section */}
                    <Box mt={2} borderBottom={1} borderColor="divider">
                        <Tabs value={activeTab} onChange={handleTabChange}>
                            <Tab label="Overview" sx={{ textTransform: "none", fontWeight: activeTab === 0 ? "bold" : "normal", color: activeTab === 0 ? "#1976d2" : "#aaa" }} />
                            <Tab label="Workouts" sx={{ textTransform: "none", fontWeight: activeTab === 1 ? "bold" : "normal", color: activeTab === 1 ? "#1976d2" : "#aaa" }} />
                            <Tab label="Meal Plans" sx={{ textTransform: "none", fontWeight: activeTab === 2 ? "bold" : "normal", color: activeTab === 2 ? "#1976d2" : "#aaa" }} />
                        </Tabs>
                    </Box>
                </CardContent>
            </Card>

            <Box mt={3}>
                {activeTab === 0 && <UserVipCodeOverView userVipCode={userVipCode} />}
                {activeTab === 1 &&
                    <UserVipCodePlans
                        selectedUserVipCode={userVipCode}
                        setLoadingText={setLoadingText}
                    />
                }
                {activeTab === 2 &&
                    <UserVipMealPlans
                        userVipCode={userVipCode}
                        setLoadingText={setLoadingText}
                    />
                }
            </Box>
        </Box>
    );
};

export default UserVipCodeTabs;
