import React, { useState } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import EditableTrainingPlan from './EditableTrainingPlan/EditableTrainingPlan';
import AddEditPlan from './AddEditPlan/AddEditPlan';
import addWorkoutPlanExampleIcon from 'src/Assets/new/addWorkoutPlanExample.jpg';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const Plans = ({
    state,
    StateEnum,
    setState,
    userVipCode,
    localCategory,
    onPlanClick,
    onPlanAdded,
    onPlanEdited,
    onPlanDeleteClick,
}) => {
    const classes = useStyles();
    const [editPlan, setEditPlan] = useState(null);

    const title = (userVipCode ? userVipCode.name + " > " : "") + (editPlan ? `Edit ${editPlan.name}` : "Add new plan");


    const PlansListContent = () => (
        <div className={classes.plansContent}>
            {localCategory.plans.map((plan) => (
                <EditableTrainingPlan
                    key={plan.id}
                    plan={plan}
                    onPlanClick={() => onPlanClick(plan)}
                    onDeleteClick={onPlanDeleteClick}
                    onEditClick={handlePlanEditClick}
                />
            ))}
        </div>
    );

    const AddWorkoutPlanContent = () => (
        <div className={classes.mainContent}>
            <p>Add your first workout plan</p>
            <div className={classes.addWorkoutPlanButton}>
                <BlueButton onClick={handleAddWorkoutPlanClick}>Add workout plan</BlueButton>
            </div>
        </div>
    );

    const ExampleImageContent = () => (
        <div className={classes.exampleContainer}>
            <img src={addWorkoutPlanExampleIcon} alt="Workout plan example" className={classes.categoriesExampleIcon} />
        </div>
    );

    const handleCloseDialog = () => {
        setState(StateEnum.LIST_PLANS);
        setEditPlan(null)
    }

    const handleAddWorkoutPlanClick = () => {
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    const handlePlanAdded = async (planName, planDescription, planType, planDifficulty, planDuration, image) => {
        try {
            await onPlanAdded(planName, planDescription, planType, planDifficulty, planDuration, image)
            setState(StateEnum.LIST_PLANS)
        } catch (error) {
            alert(error);
        }
    }

    const handlePlanEditClick = (plan) => {
        setEditPlan(plan);
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    const RenderContent = () => {
        switch (state) {
            case StateEnum.ADD_WORKOUT_PLAN:
                return (
                    <>
                        {AddWorkoutPlanContent()}
                        {ExampleImageContent()}
                    </>
                );
            case StateEnum.ADD_EDIT_PLAN:
                return (
                    <>
                        <Dialog open={state === StateEnum.ADD_EDIT_PLAN} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                            <DialogTitle>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseDialog}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <span
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                        paddingLeft: '10px'
                                    }}
                                >
                                    {title}
                                </span>
                            </DialogTitle>
                            <DialogContent>
                                <div className={classes.dialogContent}>
                                    <AddEditPlan
                                        onPlanAdded={handlePlanAdded}
                                        editPlan={editPlan}
                                        onPlanEdited={onPlanEdited}
                                    />
                                    {ExampleImageContent()}
                                </div>
                            </DialogContent>
                        </Dialog>
                    </>
                );
            case StateEnum.LIST_PLANS:
                return (
                    <div className={classes.plansListContainer}>
                        <header className={classes.header}>
                            <div className={classes.headerButton}>
                                <BlueButton onClick={handleAddWorkoutPlanClick}>Add workout plan</BlueButton>
                            </div>
                        </header>
                        {PlansListContent()}
                    </div>
                );
            default:
                return <></>;
        }
    };

    return <>{RenderContent()}</>;
};

const useStyles = makeStyles({
    plansContent: { display: 'flex', gap: 20, flexWrap: 'wrap' },
    mainContent: { flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight: 20 },
    exampleContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    categoriesExampleIcon: { maxWidth: 350 },
    addWorkoutPlanButton: { width: '250px' },
    plansListContainer: { width: '100%' },
    header: { backgroundColor: 'white', padding: 5, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', marginBottom: 100, width: '100%' },
    headerButton: { marginLeft: 'auto' },
    dialogContent: { display: 'flex', flexDirection: 'row' }
});

export default Plans;
