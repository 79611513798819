import React, { useState } from 'react';
import BackButton from 'src/Elements/BackButton';
import WorkoutDayButton from './Buttons/WorkoutDayButton';
import EmptyDayButton from './Buttons/EmptyDayButton';
import AddWeekButton from './Buttons/AddWeekButton';
import Drawer from '@material-ui/core/Drawer';
import Backdrop from '@material-ui/core/Backdrop';
import ExercisePicker from './ExercisePicker/ExercisePicker';
import Exercises from './Exercises/Exercises';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import VipUserHeader from '../../VipTraining/VipUserHeader';

import { generateUniqueId } from 'src/Utilities/Utilities';

import { MAX__WEEKS_IN_PLAN } from 'src/contants';

const TrainingCalendar = ({
    userVipCode,
    localPlan,
    editingExerciseDay,
    loginCredentials,
    isSideMenuOpen,
    openDay,
    onThreeDotsClick,
    onShouldAddExerciseClick,
    onEmptyDayClick,
    addWeek,
    onDoneClick,
    setLoadingText,
    onDayUpdated,
    useCategoriesNetwork
}) => {

    const classes = useStyles();
    const [showExercisePicker, setshowExercisePicker] = useState(false);

    const onExerciseSelected = (exercise) => {

        // Take a copy of the selected day
        var updatedDay = { ...openDay };

        // Copy the exercises array
        var newExercises = [...updatedDay.exercises];

        // Create a copy of the selected exercise, set a new UUID and index
        var newExercise = {
            ...exercise,        // Copy the existing exercise object
            id: generateUniqueId(),       // Set a new UUID for the exercise id
            index: newExercises.length // Set the index to be the current length of newExercises
        };

        // Add the new exercise to the newExercises array
        newExercises.push(newExercise);

        // Update the day with the modified exercises array
        updatedDay.exercises = newExercises;

        // Call the onDayUpdated function with the updated day object
        onDayUpdated(updatedDay);

        // Close the exercise picker
        setshowExercisePicker(false);
    };

    return (
        <div className={classes.planCalendar}>
            <div className={classes.container}>
                {localPlan.weeks.length > 0 ? (
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                <th></th>
                                {localPlan.weeks.map((week, weekIndex) => (
                                    <th key={`week-${weekIndex}`} className={classes.titleText} >
                                        <div className={classes.removeWeekButton}>
                                            <span>Week {weekIndex + 1}</span>
                                            <div className={classes.dots} onClick={(event) => {
                                                event.stopPropagation();
                                                onThreeDotsClick(event, weekIndex);
                                            }}>
                                                <span className={classes.dot}></span>
                                                <span className={classes.dot}></span>
                                                <span className={classes.dot}></span>
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: 7 }, (_, dayIndex) => (
                                <tr key={`day-row-${dayIndex}`}>
                                    <td className={classes.titleText}>Day {dayIndex + 1}</td>
                                    {localPlan.weeks.map((week, weekIndex) => {
                                        const day = week.days[dayIndex];
                                        const isAddingExercise = editingExerciseDay.day === day?.index && editingExerciseDay.week === week.index;

                                        if (day == null) {
                                            return <td key={`day-empty-${weekIndex}-${dayIndex}`} className={classes.td}>Empty</td>;
                                        }

                                        return (
                                            <td key={`day-${weekIndex}-${dayIndex}`} className={classes.td}>
                                                <div className="day-content">
                                                    {day.exercises && day.exercises.length > 0 ? (
                                                        <WorkoutDayButton
                                                            day={day}
                                                            authorId={loginCredentials.authorId}
                                                            onDayClick={() => onShouldAddExerciseClick(day, week)}
                                                        />
                                                    ) : (
                                                        <EmptyDayButton
                                                            isAddingExercise={isAddingExercise}
                                                            onIsAddingExerciseClick={() => onEmptyDayClick(day, week)}
                                                            onClick={() => onEmptyDayClick(null, null)}
                                                            onShouldAddExerciseClick={() => onShouldAddExerciseClick(day, week)}
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>) : null}
                {localPlan.weeks.length < MAX__WEEKS_IN_PLAN && (
                    <div className={classes.addWeekContainer}>
                        <AddWeekButton className={classes.addWeekButton} onClick={addWeek}>
                            Add Week
                        </AddWeekButton>
                    </div>
                )}

                <Drawer
                    className={classes.drawer}
                    variant="temporary"
                    anchor="right"
                    open={isSideMenuOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={onDoneClick}
                    disableEnforceFocus={true}
                >
                    <Exercises
                        day={openDay}
                        plan={localPlan}
                        planId={localPlan.id}
                        onDayUpdated={onDayUpdated}
                        onDoneClick={onDoneClick}
                        setLoadingText={setLoadingText}
                        useCategoriesNetwork={useCategoriesNetwork}
                        onAddExistingExerciseClick={() => setshowExercisePicker(true)}
                        canPickExisting={!useCategoriesNetwork}
                    />
                </Drawer>
                <Dialog
                    open={showExercisePicker}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        Pick from existing exercises
                        <IconButton
                            aria-label="close"
                            onClick={()=>setshowExercisePicker(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ flexGrow: 1, overflow: 'auto' }}>
                        <ExercisePicker
                            onClose={() => setshowExercisePicker(false)}
                            onExerciseSelected={onExerciseSelected}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    titleText: {
        color: '#6B6B6B',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        whiteSpace: 'nowrap'
    },
    container: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    table: {
        marginLeft: '40px',
        flex: 0,
        borderCollapse: 'collapse'
    },
    td: {
        padding: 3
    },
    addWeekContainer: {
        marginLeft: '16px',
        paddingTop: '5px',
    },
    addWeekButton: {
        width: '130px',
        height: '36px',
        whiteSpace: 'nowrap'
    },
    removeWeekButton: {
        color: '#6B6B6B',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        background: 'transparent',
        border: 'none'
    },
    removeIcon: {
        paddingLeft: 10
    },
    drawer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        flexShrink: 0
    },
    drawerPaper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        backgroundColor: '#E4E9ED'
    },
    backdrop: {
        zIndex: 1000,
        color: '#fff',
    },
    pickerBackdrop: {
        zIndex: 10000,
        color: '#fff',
    },
    dots: {
        cursor: 'pointer',
        display: 'flex', // Use flex to facilitate the vertical layout
        flexDirection: 'column', // Stack children vertically
        padding: '0 7px',
        height: '14px', // Set a fixed height if needed
        justifyContent: 'space-between', // Distribute the dots evenly vertically
    },
    dot: {
        height: '3px', // Example height for a single dot
        width: '3px', // Example width for a single dot
        backgroundColor: '#6B6B6B',
        borderRadius: '50%', // Make the dot circular
    }
}));

export default TrainingCalendar;
