import { NetworkProvider } from "src/NetworkProvider";
import { video_thumbnail_url_from_file_name } from 'src/Utilities/Utilities';

class CategoriesReducer {

  static sortedCategories = (categories) => {
    categories.forEach(item => {
      if (item.plans && Array.isArray(item.plans)) {
        item.plans.sort((a, b) => a.index - b.index);

        item.plans.forEach(plan => {
          if (plan.weeks && Array.isArray(plan.weeks)) {
            plan.weeks.sort((a, b) => a.index - b.index);

            plan.weeks.forEach(week => {
              if (week.days && Array.isArray(week.days)) {
                week.days.sort((a, b) => a.index - b.index);

                week.days.forEach(day => {
                  if (day.exercises && Array.isArray(day.exercises)) {
                    day.exercises.sort((a, b) => a.index - b.index);
                  }
                });
              }
            });
          }
        });
      }
    });
    return categories
  }

  static getAllExercises = (categories) => {
    let exercisesList = [];

    categories.forEach(item => {
      if (item.plans && Array.isArray(item.plans)) {
        item.plans.forEach(plan => {
          if (plan.weeks && Array.isArray(plan.weeks)) {
            plan.weeks.forEach(week => {
              if (week.days && Array.isArray(week.days)) {
                week.days.forEach(day => {
                  if (day.exercises && Array.isArray(day.exercises)) {
                    exercisesList = exercisesList.concat(day.exercises);
                  }
                });
              }
            });
          }
        });
      }
    });

    return exercisesList;
  };

  static sortedRecipes = (recipes) => {
    const sortByIndex = (a, b) => {
      return a.index - b.index;
    };

    recipes.forEach(recipe => {

      if (recipe.tags && Array.isArray(recipe.tags)) {
        recipe.tags.sort(sortByIndex);
      }
      if (recipe.ingredients && Array.isArray(recipe.ingredients)) {
        recipe.ingredients.forEach((ingredient, currentIndex) => {
          if (ingredient.index === null || ingredient.index === undefined) {
            ingredient.index = currentIndex;
          }
        });
        recipe.ingredients.sort(sortByIndex);
      }
      if (recipe.methods && Array.isArray(recipe.methods)) {
        recipe.methods.sort(sortByIndex);
      }
    });

    return this.sortByCreatedAt(recipes);
  }

  static assignRecipesToPlans = (mealPlans, recipes) => {
    const sortedRecipes = this.sortedRecipes(recipes);

    const categoryMap = new Map(mealPlans.map(category => [category.id, category]));

    mealPlans.forEach(category => {
      if (!category.recipes) {
        category.recipes = []
      }
    });

    let unassignedCategory = { id: "unassigned", title: "Unassigned", index: mealPlans.length + 1, recipes: [] };

    sortedRecipes.forEach(recipe => {
      const category = categoryMap.get(recipe.foodcategoryid);

      if (category) {
        category.recipes.push(recipe);
      } else {
        console.warn(`Category ID ${recipe.foodcategoryid} not found in meal plans. Assigning to 'Unassigned'.`);
        unassignedCategory.recipes.push(recipe);
      }
    });

    if (unassignedCategory.recipes.length > 0) {
      mealPlans.push(unassignedCategory);
    }
    return this.sortByIndex(mealPlans);
  };


  static sortByCreatedAt = (data) => {
    return [...data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  };

  static sortByIndex = (data) => {
    return [...data].sort((a, b) => new Date(a.index) - new Date(b.index));
  };

  static extractVideos = (plan) => {
    return plan.weeks.reduce((weekAcc, week) => {
      const weekVideos = week.days.reduce((dayAcc, day) => {
        const dayVideos = day.exercises.reduce((exerciseAcc, exercise) => {
          if (exercise.video) {
            exerciseAcc.push(exercise.video);
          }
          return exerciseAcc;
        }, []);
        return dayAcc.concat(dayVideos);
      }, []);
      return weekAcc.concat(weekVideos);
    }, []);
  };

  static extractVideoCountsFromPlan = (plan) => {
    return plan.weeks.reduce((acc, week) => {
      week.days.forEach(day => {
        day.exercises.forEach(exercise => {
          if (exercise.video) {
            acc[exercise.video] = (acc[exercise.video] || 0) + 1;
          }
        });
      });
      return acc;
    }, {});
  };

  static deletePlan = async (loginCredentials, setLoginCredentials, plan) => {
    if (plan.image) {
      await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, plan.image)
    }
    const videos = CategoriesReducer.extractVideos(plan)

    await Promise.all(videos.map(async (video) => {
      try {
        const response = await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, video);
        return response;
      } catch (error) {
        console.error(`Error deleting video ${video}:`, error);
        throw error;
      }
    }));
  }


  static deleteWeek = async (loginCredentials, setLoginCredentials, week, plan) => {
    const videoCounts = CategoriesReducer.extractVideoCountsFromPlan(plan);

    const videosToDelete = week.days.flatMap(day =>
      day.exercises.filter(ex => videoCounts[ex.video] === 1).map(ex => ex.video)
    );

    await Promise.all(videosToDelete.map(async (video) => {
      try {
        await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, video);
        const thumbnailUrl = video_thumbnail_url_from_file_name(video, loginCredentials.authorId);
        if (thumbnailUrl) {
          await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, thumbnailUrl);
        }
      } catch (error) {
        console.error(`Error deleting video ${video}:`, error);
        throw error;
      }
    }));
  };

  static getMaxWeekIndex = (weeks) => {
    return weeks.reduce((max, week) => week.index > max ? week.index : max, -1);
  };
}

export default CategoriesReducer;