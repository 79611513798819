import React, { useState} from 'react';
import BlueButton from 'src/Elements/BlueButton';
import emptyRecipeIcon from 'src/Assets/new/emptyRecipeIcon.png';
import { makeStyles } from '@material-ui/core/styles';
import Recipe from './Recipe';
import EditAddRecipe from './EditAddRecipe';
import DeleteConfirmDialog from 'src/Utilities/Popups/DeleteConfirmDialog';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const StateEnum = {
    RECIPES: 0,
    EDITADDRECIPE: 1
};

const Recipes = ({
    openMealPlan,
    openRecipe,
    setOpenRecipe,
    foodCategories,
    setLoadingText,
    onBack,
    onAddRecipe,
    onEditedRecipe,
    onDeleteRecipe
}) => {


    const [state, setState] = useState(StateEnum.RECIPES);
    const classes = useStyles();
    const [recipeToDelete, setRecipeToDelete] = useState(null);

    const filteredRecipes = openMealPlan.recipes;

    const title = openMealPlan
        ? `${openMealPlan.title} > ${openRecipe ? `Edit ${openRecipe?.name}` : "Add recipe"}`
        : "";



    const onRecipeEditClick = (recipe) => {
        setOpenRecipe(recipe);
        setState(StateEnum.EDITADDRECIPE);
    }

    const onRecipeClose = (recipeToClose) => {
        setState(StateEnum.RECIPES);

        if (recipeToClose) {
            onEditedRecipe(recipeToClose)
        }
        setOpenRecipe(null);
    };

    const handleAddRecipeClick = () => {
        setOpenRecipe(null)
        setState(StateEnum.EDITADDRECIPE)
    }

    const RecipesListContent = () => {

        if (filteredRecipes.length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        <img src={emptyRecipeIcon} alt="Recipe Example" className={classes.categoriesExampleIcon} />
                        <h3 className={classes.exampleTitle}>Lets add your first recipe</h3>
                        <BlueButton onClick={handleAddRecipeClick}>Add Recipe</BlueButton>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={handleAddRecipeClick}>Add recipe</BlueButton>
                        </div>
                    </header>
                    <div className={classes.contentArea}>
                        <div className={classes.categoryList}>
                            {filteredRecipes.map((recipe) => (
                                <Recipe
                                    recipe={recipe}
                                    onEditClick={() => onRecipeEditClick(recipe)}
                                    onDeleteClick={() => setRecipeToDelete(recipe)}
                                />
                            ))}
                        </div>
                        { /*<div className={classes.exampleContainer}>
                            <h3 className={classes.exampleTitle}>Example recipes</h3>
                            <img src={createRecipeIcon} alt="Recipes Example" className={classes.categoriesExampleIcon} />
                        </div>*/}
                    </div>
                    <DeleteConfirmDialog
                        open={recipeToDelete != null}
                        subtitle={recipeToDelete ? "Are you sure you want to delete " + recipeToDelete.name + "?" : null}
                        onConfirm={handleDeleteRecipe}
                        onCancel={() => setRecipeToDelete(null)}
                    />
                </div>
            )
        }
    };

    const handleAddRecipe = async (recipeToAdd) => {
        await onAddRecipe(recipeToAdd)
        setState(StateEnum.RECIPES)
    }

    const handlEditRecipe = async (recipeToChange) => {
        await onEditedRecipe(recipeToChange)
        setState(StateEnum.RECIPES)
    }

    const handleDeleteRecipe = async () => {
        await onDeleteRecipe(recipeToDelete.id)
        setRecipeToDelete(null)
        setState(StateEnum.RECIPES)
    }

    return (
        <React.Fragment>
            <RecipesListContent />
            <Dialog
                open={state == StateEnum.EDITADDRECIPE}
                maxWidth="sm"
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setState(StateEnum.RECIPES)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <span
                        style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            paddingLeft: '10px'
                        }}
                    >
                        {title}
                    </span>
                </DialogTitle>
                <DialogContent sx={{ flexGrow: 1, overflow: 'auto' }}>
                    <EditAddRecipe
                        openRecipe={openRecipe}
                        openMealPlan={openMealPlan}
                        foodCategories={foodCategories}
                        onClose={onRecipeClose}
                        setLoadingText={setLoadingText}
                        onAddRecipe={handleAddRecipe}
                        onEditedRecipe={handlEditRecipe}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000'
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap'
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 20,
    },
    headerButton: {
        marginLeft: '5px',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%'
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    }
});

export default Recipes;
