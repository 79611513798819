class VipCodeReducer {

    static parseUserVipCodeMealPlans(userVIpCode) {
        try {
            return JSON.parse(userVIpCode.vipcode["mealplans"])
        } catch (error) {
            return []
         }
        
    }

    static parsedUserVipCodes(userVIpCode) {

        const result = {};
        const categories = ["calories", "water", "weight", "workouts"];

        let firstWeight = undefined;
        let lastDate = undefined;
        let firstDate = undefined;
        categories.forEach(category => {
            userVIpCode[category].forEach(entry => {
                const date = entry.created_at ? entry.created_at.split("T")[0] : entry.date.split("T")[0];

                if (!result[date]) {
                    result[date] = {
                        calories: [],
                        exercises: [],
                        water: undefined,
                        weight: undefined,
                        firstWeight: undefined,
                        carbs: 0,
                        fat: 0,
                        protein: 0,
                        eatenCalories: 0,
                        burnedCalories: 0
                    };
                }

                if (category === "water") {
                    // Keep only the record with the highest ID for water
                    if (!result[date].water || entry.id > result[date].water.id) {
                        result[date].water = entry;
                    }
                } else if (category === "weight") {
                    // Store the weight with the latest created_at (most recent)
                    if (!result[date].weight || entry.id > result[date].weight.id) {
                        result[date].weight = entry;
                    }
                } else if (category == "workouts") {
                    result[date].exercises.push(entry);
                    result[date].burnedCalories += Number(entry.calories)
                } else if (category == "calories") {
                    try {
                        const macros = JSON.parse(entry.text)
                        result[date] = {
                            ...result[date],
                            carbs: Math.floor(result[date].carbs + Number(macros.Carbs) || 0),
                            fat: Math.floor(result[date].fat + Number(macros.Fat) || 0),
                            protein: Math.floor(result[date].protein + Number(macros.Protein) || 0),
                            eatenCalories: Math.floor(result[date].eatenCalories + Number(macros.Calories) || 0),
                        };
                    } catch {

                    }
                }


                if (!lastDate || new Date(date) > new Date(lastDate)) {
                    lastDate = date;
                }

                if (!firstDate || new Date(date) < new Date(firstDate)) {
                    firstDate = date;
                }
            });
        });

        let goal = undefined;
        let requiredProtein = undefined;
        let requiredFat = undefined;
        let requiredCarbs = undefined;
        let height = undefined;
        try {
            const estimation = JSON.parse(userVIpCode["estimation"]);
            goal = estimation.goal;
            height = estimation.height;

            const macrosResult = this.calculateMacros(
                userVIpCode.caloriesIntake,
                estimation.diet,
                estimation.gender,
                estimation.weight
            )
            requiredProtein = macrosResult.proteinIntake;
            requiredFat = macrosResult.fatIntake;
            requiredCarbs = macrosResult.carbsIntake;
        } catch (error) { }

        firstWeight = result[firstDate].weight;

        return {
            userData: result,
            firstWeight: firstWeight,
            goal,
            requiredProtein,
            requiredFat,
            requiredCarbs,
            lastDate,
            caloriesIntake: userVIpCode.caloriesIntake,
            height
        };
    }

    static sortedUserCodes = (userCodes) => {
        return userCodes.sort((a, b) => {
            return new Date(b.vipcode.created_at) - new Date(a.vipcode.created_at);
        });
    };

    static calculateMacros(caloriesIntake, diet, gender, weight) {
        let fatProportion, carbProportion;

        // Determine macronutrient proportions based on the diet
        switch (diet) {
            case 'Low-Carb High-Fat Diet':
                fatProportion = 0.85;
                carbProportion = 0.15;
                break;
            case 'High protein diet':
                fatProportion = 0.5;
                carbProportion = 0.5;
                break;
            case 'Balanced diet':
            default: // Default to a balanced diet
                fatProportion = 0.4;
                carbProportion = 0.6;
                break;
        }

        // Calculate initial macronutrient intake
        let proteinIntake = weight * 2; // Protein in grams
        const proteinCalories = proteinIntake * 4; // Protein contribution in calories

        // Remaining calories after protein
        const remainingCalories = caloriesIntake - proteinCalories;

        // Calculate fat and carb intake in grams
        let fatIntake = (remainingCalories * fatProportion) / 9;
        let carbsIntake = (remainingCalories * carbProportion) / 4;

        // Adjust protein intake for females if it exceeds 150 grams
        if (gender === 'female' && proteinIntake > 150) {
            const leftover = proteinIntake - 150; // Grams of protein above the cap
            const leftoverCalories = leftover * 4; // Convert leftover protein grams to calories

            // Adjust protein intake to the cap
            proteinIntake = 150;

            // Redistribute leftover calories
            const fatCalories = leftoverCalories * fatProportion;
            const carbCalories = leftoverCalories * carbProportion;

            const fatGrams = fatCalories / 9;
            const carbGrams = carbCalories / 4;

            // Add redistributed grams to daily intake
            fatIntake += fatGrams;
            carbsIntake += carbGrams;
        }

        // Return rounded values
        return {
            proteinIntake: Math.round(proteinIntake),
            fatIntake: Math.round(fatIntake),
            carbsIntake: Math.round(carbsIntake)
        };
    }

}

export default VipCodeReducer;