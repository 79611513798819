import React, { useState, useEffect, useContext } from "react";
import MealPlans from "../../Recipes/FoodCategories/MealPlans";
import VipCodeReducer from "../UserVipCode/VipCodeReducer";
import { CategoriesContext } from "../../Categories/CategoriesContext";
import { generateUniqueId } from 'src/Utilities/Utilities';

const UserVipMealPlans = ({ userVipCode, setLoadingText }) => {
    const [mealPlans, setMealPlans] = useState(VipCodeReducer.parseUserVipCodeMealPlans(userVipCode));
    const [editMealPlan, setEditMealPlan] = useState(null);
    const [deleteMealPlan, setDeleteMealPlan] = useState(null);
    const [openRecipe, setOpenRecipe] = useState(null);
    const [openMealPlan, setOpenMealPlan] = useState(null);
    const { updateUserVIPCodeMealPlans } = useContext(CategoriesContext)

    useEffect(() => {
        if (openMealPlan) {
            const updatedMealPlan = mealPlans.find(plan => plan.id === openMealPlan.id);
            if (updatedMealPlan) {
                setOpenMealPlan(updatedMealPlan);
            } else {
                setOpenMealPlan(null);
            }
        }
        if (openRecipe) {
            const updatedOpenRecipe = mealPlans.find(recipe => recipe.id === openRecipe.id);
            if (updatedOpenRecipe) {
                setOpenRecipe(updatedOpenRecipe);
            } else {
                setOpenRecipe(null);
            }
        }
    }, [mealPlans]);


    const onCreateMealPlan = async (planName) => {
        try {
            const planData = {
                "id": generateUniqueId(),
                title: planName,
                recipes: []
            }
            const newPlans = [...mealPlans, planData];
            await updateUserVIPCodeMealPlans(userVipCode, newPlans);
            setMealPlans(newPlans)
        } catch (error) {
            window.alert(error);
        }
    }

    const onReorderMealPlans = async (newPlans) => {
        try {
            await updateUserVIPCodeMealPlans(userVipCode, newPlans);
        } catch (error) {
            window.alert(error);
        }
    }

    const onDeleteMealPlan = async (planToDelete) => {

        try {
            const newPlans = mealPlans.filter(plan => plan.id !== planToDelete.id);
            await updateUserVIPCodeMealPlans(userVipCode, newPlans);

            setMealPlans(newPlans);
            setDeleteMealPlan(null);
        } catch (error) {
            window.alert(error);
        }
    };

    const onAddRecipe = async (recipeToAdd) => {
        try {
            if (!openMealPlan) {
                return;
            }

            const updatedMealPlan = {
                ...openMealPlan,
                recipes: [...(openMealPlan.recipes || []), recipeToAdd]
            };

            const newPlans = mealPlans.map(plan =>
                plan.id === openMealPlan.id ? updatedMealPlan : plan
            );

            await updateUserVIPCodeMealPlans(userVipCode, newPlans);

            setMealPlans(newPlans);
            setOpenRecipe(null)
        } catch (error) {
            window.alert(error);
        }
    };

    const onEditedRecipe = async (updatedRecipe) => {
        try {
            if (!openMealPlan) {
                return;
            }

            console.log(updatedRecipe);

            const updatedMealPlan = {
                ...openMealPlan,
                recipes: openMealPlan.recipes.map(recipe =>
                    recipe.id === updatedRecipe.id ? updatedRecipe : recipe
                )
            };

            const newPlans = mealPlans.map(plan =>
                plan.id === openMealPlan.id ? updatedMealPlan : plan
            );

            await updateUserVIPCodeMealPlans(userVipCode, newPlans);
            setMealPlans(newPlans);
            setOpenRecipe(null);
        } catch (error) {
            window.alert(error);
        }
    };

    const onDeleteRecipe = async (recipeId) => {
        try {
            if (!openMealPlan) {
                return;
            }

            // Step 1: Remove the recipe with the given `recipeId`
            const updatedMealPlan = {
                ...openMealPlan,
                recipes: openMealPlan.recipes.filter(recipe => recipe.id !== recipeId)
            };

            // Step 2: Update the meal plans list
            const newPlans = mealPlans.map(plan =>
                plan.id === openMealPlan.id ? updatedMealPlan : plan
            );


            // Step 3: Sync changes with the backend
            await updateUserVIPCodeMealPlans(userVipCode, newPlans);

            // Step 4: Update state
            setMealPlans(newPlans);
            setOpenRecipe(null);
        } catch (error) {
            window.alert(error);
        }
    };


    return (
        <div>
            <MealPlans
                mealPlans={mealPlans}
                setMealPlans={setMealPlans}
                editMealPlan={editMealPlan}
                setEditMealPlan={setEditMealPlan}
                setLoadingText={setLoadingText}
                openRecipe={openRecipe}
                setOpenRecipe={setOpenRecipe}
                onCreateMealPlan={onCreateMealPlan}
                onReorderMealPlans={onReorderMealPlans}
                onDeleteMealPlan={onDeleteMealPlan}
                deleteMealPlan={deleteMealPlan}
                setDeleteMealPlan={setDeleteMealPlan}
                onAddRecipe={onAddRecipe}
                openMealPlan={openMealPlan}
                setOpenMealPlan={setOpenMealPlan}
                onEditedRecipe={onEditedRecipe}
                onDeleteRecipe={onDeleteRecipe}
            />
        </div>
    );
};

export default UserVipMealPlans;
